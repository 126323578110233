import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';

import App from 'app';
import AppProviders from 'contexts';
import * as serviceWorker from './serviceWorker';
import store, { persistor } from './app/store';

import './index.scss';

ReactGA.initialize('UA-140836063-4', {
  alwaysSendToDefaultTracker: false,
});

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AppProviders>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AppProviders>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  );
};

Sentry.init({
  dsn: 'https://3453c2b3c04a427f9a5c20b0a7970164@sentry.io/1546724',
});

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
