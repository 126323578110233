import React from 'react';

import { retryPromise } from 'utils';
import { useUser } from 'contexts/userContext';
import Loading from 'components/loading';

import './App.scss';

const loadAuthenticatedApp = () => import('./AuthenticatedApp');
const AuthenticatedApp = React.lazy(() => retryPromise(loadAuthenticatedApp));
const UnauthenticatedApp = React.lazy(() =>
  retryPromise(() => import('./UnauthenticatedApp'))
);

const App = () => {
  const user = useUser();

  React.useEffect(() => {
    loadAuthenticatedApp();
  }, []);

  return (
    <React.Suspense fallback={<Loading />}>
      {user?.name ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  );
};

export default App;
