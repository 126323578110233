import { combineReducers } from 'redux';

import misc from 'reducers/miscDux';
import author from 'reducers/authorDux';

const rootReducer = combineReducers({
  misc,
  author,
});

export default rootReducer;
