import store from 'app/store';

import TokenUtils from 'utils/tokenUtils';
import { setUser, clearUser } from 'reducers/miscDux';
// import { setAuthorUser, clearAuthorUser } from 'reducers/authorDux';
import ApiService from './apiService';
// import AuthorAPIService from './authorApiService';

const logout = () => {
  TokenUtils.removeToken();
  store.dispatch(clearUser());
  // store.dispatch(clearAuthorUser());
  return Promise.resolve();
};

const login = async (token) => {
  const response = await ApiService.post('/mentor/google-login', {
    token,
  }).catch((error) => {
    return Promise.reject(new Error(error));
  });
  return TokenUtils.storeToken(response.data.token);
};

const getUser = async () => {
  const token = TokenUtils.getToken();
  // No user yet.
  if (!token) {
    return Promise.resolve(null);
  }

  // Check with backend to see if key is still valid
  try {
    const response = await ApiService.get('/mentor');
    // const authorResponse = await AuthorAPIService.get('/author');
    if (response.status === 200) {
      const userData = response.data;
      store.dispatch(setUser({ ...userData, lastRetrieved: Date.now() }));
      return userData;
    }
    // if (authorResponse.status === 200) {
    //   const authorData = authorResponse.data; // name, id, privilege
    //   store.dispatch(
    //     setAuthorUser({ ...authorData, lastRetrieved: Date.now() })
    //   );
    // }
    throw new Error({
      status: response.statusText,
      message: response.statusText,
    });
  } catch (error) {
    logout();
    return Promise.reject(new Error(error));
  }
};

export default {
  login,
  logout,
  getUser,
};
