// https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
const retryPromise = (promise, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    promise()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retryPromise(promise, retriesLeft - 1, interval).then(
            resolve,
            reject
          );
        }, interval);
      });
  });
};

const mergeUrls = (urlOne, urlTwo) => {
  if (urlOne[urlOne.length - 1] === '/' && urlTwo[0] === '/') {
    return urlOne + urlTwo.substring(1);
  }
  if (urlOne[urlOne.length - 1] !== '/' && urlTwo[0] !== '/') {
    return `${urlOne}/${urlTwo}`;
  }
  return urlOne + urlTwo;
};

export { retryPromise, mergeUrls };
