export const TOKEN_KEY = 'currentMentorUser';

const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

const storeToken = (token) => {
  try {
    localStorage.setItem(TOKEN_KEY, token);
    return Promise.resolve(null);
  } catch (error) {
    return Promise.reject(new Error());
  }
};

const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export default { getToken, storeToken, removeToken };
