import React, { useState } from 'react';
import { useAuth } from 'contexts/authContext';

const UserContext = React.createContext();

const UserProvider = (props) => {
  const { data } = useAuth();
  const [savedQns, setSavedQns] = useState(data?.savedQuestions ?? []);
  const [qnCounter, setQnCounter] = useState(0);
  const addQn = (qn) => {
    const idx = savedQns.indexOf(qn);
    if (idx === -1 && qn) {
      setSavedQns((qns) => [...qns, qn]);
      setQnCounter((c) => c + 1);
    }
  };
  const removeQn = (qn) => {
    const idx = savedQns.indexOf(qn);
    if (idx > -1) {
      setSavedQns((qns) => qns.filter((q) => q !== qn));
      setQnCounter((c) => c - 1);
    }
  };
  return (
    <UserContext.Provider
      value={{ ...data, qnCounter, savedQns, addQn, removeQn }}
      {...props}
    />
  );
};

const useUser = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context;
};

export { UserProvider, useUser };
