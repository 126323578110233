import React from 'react';
import Firebase from 'components/firebase/Firebase';

const FirebaseContext = React.createContext();
const firebase = new Firebase();

export const FirebaseProvider = (props) => {
  return <FirebaseContext.Provider value={firebase} {...props} />;
};

export const useFirebase = () => {
  const context = React.useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error(`useFirebase must be used within a FirebaseProvider`);
  }
  return context;
};
