import React, { useEffect } from 'react';
import { useAsync } from 'react-async';
import { useFirebase } from 'contexts/firebaseContext';

import AuthService from 'services/authService';
import Loading from 'components/loading';

const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false);
  const firebase = useFirebase();
  const {
    data = null,
    error,
    isRejected,
    isPending,
    isSettled,
    reload,
  } = useAsync({
    promiseFn: AuthService.getUser,
  });

  const init = () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken(true);
        AuthService.login(token)
          .then(() => {
            // firebase.auth().signOut();
            reload();
          })
          .catch((e) => {
            return Promise.reject(new Error(e));
          });
      }
    });
  };

  useEffect(init, []);

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
    }
  }, [isSettled]);

  if (!firstAttemptFinished) {
    if (isPending) {
      return <Loading />;
    }
    if (isRejected) {
      return (
        <div css={{ color: 'red' }}>
          <p>Uh oh... There&apos;s a problem. Try refreshing the app.</p>
          <pre>{error.message}</pre>
        </div>
      );
    }
  }

  const login = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider);
  };

  const logout = () => {
    firebase.auth().signOut();
    AuthService.logout().then(reload);
  };

  return <AuthContext.Provider value={{ data, login, logout }} {...props} />;
};
const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};
export { AuthProvider, useAuth };
