/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const author = createSlice({
  name: 'author',
  initialState: {
    name: null,
    lastRetrieved: null,
    privilege: null,
    id: null,
  },
  reducers: {
    setAuthorUser: async (state, { payload }) => {
      state.name = payload.name;
      state.privilege = payload.privilege;
      state.id = payload.id;
      state.lastRetrieved = payload.lastRetrieved;
    },
    clearAuthorUser: (state) => {
      state.name = null;
      state.id = null;
      state.privilege = null;
    },
  },
});

export const { setAuthorUser, clearAuthorUser } = author.actions;

export default author.reducer;
