/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const misc = createSlice({
  name: 'misc',
  initialState: {
    name: null,
    primary: null,
    secondary: null,
    lastRetrieved: null,
  },
  reducers: {
    setUser: (state, { payload }) => {
      state.name = payload.name;
      state.primary = payload.primary.join();
      state.secondary = payload.secondary.join();
      state.lastRetrieved = payload.lastRetrieved;
    },
    clearUser: (state) => {
      state.name = null;
      state.primary = null;
      state.secondary = null;
    },
    updateClassLevels: (state, { payload }) => {
      state.primary = payload.primary.join();
      state.secondary = payload.secondary.join();
    },
  },
});

export const { setUser, clearUser, updateClassLevels } = misc.actions;

export default misc.reducer;
